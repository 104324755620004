import styled from 'styled-components';

const LinkButton = styled.a`
  display: inline-block;
  background-color: ${(props) => props.theme.colors.orange};
  color: ${(props) => props.theme.colors.lightPeach2};
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
  padding: 7px 14px;
  border-radius: 8px;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
  }
`;

export const Button = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.colors.orange};
  color: ${(props) => props.theme.colors.lightPeach2};
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
  padding: 7px 14px;
  border-radius: 8px;
  text-align: center;
  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
  }
`;

export default LinkButton;
